/* Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0*/
@import '../node_modules/bootstrap/dist/css/bootstrap.css';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1, h2, h3, h4, h5 {
  line-height: initial
}

#root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fixfloat:after {
  content: "";
  display: table;
  clear: both;
}

@keyframes App-logo-spin {
  from { transform: scale(1); }
  to { transform: scale(1.08); }
}

/* fix semantic-ui quirks */
.ui.negative.floating.message, .ui.attached.negative.floating.message {
  box-shadow:
    0px 0px 0px 1px #E0B4B4 inset,
    0px 2px 4px 0px rgba(34, 36, 38, 0.12),
    0px 2px 10px 0px rgba(34, 36, 38, 0.15)
}

.ui.vertical.menu .menu .item {
  font-size: 0.9em;
}

.ui.vertical.menu .menu .menu .item {
  margin-left: 1em;
  font-size: 1em;
}

.ui.inverted.menu .menu a.item:hover {
  color: #ffffff;
}

button.btn.authorize.unlocked,.base-url{
  display: none !important;
}

button.authorization__btn.unlocked, .try-out {
    display: none !important;
}

.col.parameters-col_name,.col.response-col_status {
    max-width: 20% !important;
}

img.ui.mini.image {
width: 250px !important;
}